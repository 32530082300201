<template>
    <v-dialog
        v-model="dialog"
        max-width="350"
        persistent
    >
        <v-form
            ref="signoForm"
            @submit="agrega"
            onSubmit="return false;"
            v-model="validaSigno"
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Agregar {{paramSign.nombreLargo}}
                </v-card-title>
                <v-card-text>
                        <div class="my-2">
                            <v-expand-transition>
                                <v-alert v-if="alertHoraExistente" type="warning" text border="left" dense>
                                    <span class="subtitle-2">Ya existe un registro a esa hora</span>
                                </v-alert>
                            </v-expand-transition>

                            <span>{{moment(moment(paramSign.estaFecha, "DD-MM-YYYY")).format("dddd MMMM DD, YYYY")}}</span>
                        </div>
                        <v-text-field
                            ref="signoHora"
                            v-model="datosSigno.hora"
                            :rules="[
                                value => !!value || 'Requerido.',
                                value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                            ]"
                            filled
                            rounded
                            clearable
                            dense
                            label="Hora (HH:MM formato 24 hrs)"
                            @blur="buscaSigno"
                        ></v-text-field>
                        <!-- Para los datos numéricos -->
                        <v-text-field
                            v-if="!camposTa(paramSign.signo)"
                            v-model="datosSigno.valor"
                            :rules="[
                                value => !!value || 'Requerido.',
                                value => value >= paramSign.min || 'Valor MENOR al mínimo ('+paramSign.min+')',
                                value => value <= paramSign.max || 'Valor MAYOR al máximo ('+paramSign.max+')'
                            ]"
                            type="number"
                            filled
                            rounded
                            clearable
                            dense
                            :label="paramSign.nombreLargo"
                        ></v-text-field>

                        <!-- para los datos de texto como la TA -->
                        <v-text-field
                            v-if="camposTa(paramSign.signo)"
                            v-model="datosSigno.valor"
                            :rules="[
                                value => !!value || 'Requerido.',
                            ]"
                            filled
                            rounded
                            clearable
                            dense
                            :label="paramSign.nombreLargo"
                        ></v-text-field>

                        <v-text-field
                            v-model="datosSigno.observaciones"
                            filled
                            rounded
                            clearable
                            dense
                            label="Observaciones"
                        ></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        color="success"
                        type="submit"
                        :disabled="!validaSigno"
                        :loading="loadingSigno"
                    >
                        Agregar
                    </v-btn>

                    <v-btn color="error" @click="cancelaAgrega">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import Swal from "sweetalert2"
import 'sweetalert2/dist/sweetalert2.min.css'

export default {
    mounted(){
    },
    props:{
        dialogSignos:Boolean,
        paramSign:Object,
        listaSign:Array,
        estaFecha:String,
        estaHora: String,
        internamiento: Number
        //valor: Number
        //signo: String,
        //fecha: String,
        //min: Number,
        //max: Number,
        //nombreLargo:String,
        //cip:Number
    },
    data:()=>({
        validaSigno:false,
        loadingSigno:false,
        alertHoraExistente:false,
        datosSigno:{
            observaciones:'',
            hora:'',
            valor:'',
            fecha:'',
            paciente:null,
            internamiento:null,
        },

    }),
    methods:{
        async agrega(){
            this.loadingSigno = true
            //this.datosSigno.fecha = this.paramSign.estaFecha
            //this.datosSigno.internamiento = this.paramSign.internamiento
            //this.datosSigno.signo = this.paramSign.signo

            this.datosSigno.fecha = this.estaFecha
            this.datosSigno.internamiento = this.internamiento
            this.datosSigno.signo = this.paramSign.signo
            try {
                let sg = await this.$http({
                    url:'/enfermeria/guardaSignos',
                    method:'POST',
                    data: this.datosSigno
                })
                if (sg.data.estado == true){
                    this.$refs.signoForm.reset()
                    this.dialog=false
                    this.$refs.signoForm.reset()
                    this.$emit('signoAgregado')
                } else {
                    console.log(sg.data)
                    Swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                this.loadingSigno = false
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loadingSigno = false
            }
        },
        buscaSigno(){
            this.alertHoraExistente = false
            if (this.$refs.signoHora.valid){
                let v1 = this.datosSigno.hora.split(':')
                let hr = parseInt(v1[0], 10);

                if (hr < 10){
                    this.datosSigno.hora = '0'+hr+':'+v1[1]
                }

                for (let ele of this.listaSign)
                {
                    let horaCompara = ''
                    let v2 = ele.hora.split(':')
                    let hr2 = parseInt(v2[0], 10);
                    if (hr2 < 10){
                        horaCompara = '0'+hr2+':'+v2[1]
                    } else {
                        horaCompara = hr2+':'+v2[1]
                    }
                    //console.log(horaCompara +'->'+this.datosSigno.hora+'='+(horaCompara==this.datosSigno.hora))

                    if (horaCompara==this.datosSigno.hora){
                        //estado = true
                        this.alertHoraExistente = true
                        break
                    }
                }
                // if (estado){
                //     Swal.fire({
                //         icon: 'warning',
                //         title: 'Registro existente',
                //         text: 'Ya existe un registro a la misma hora',
                //     })
                // }

            }
        },

        camposTa(val){
            //console.log(val == 'ta' || val == 'taIn')
            return (val == 'ta' || val == 'taIn' || val == 'tipov')
        },

        cancelaAgrega(){
            this.alertHoraExistente = false
            this.dialog=false
            this.$refs.signoForm.reset()
        },
    },
    computed:{
        dialog:{
            get(){
                return this.dialogSignos
            },
            set(value){
                //console.log(value)
                this.$emit('update:dialogSignos',value)
            },
        }
    },
    watch:{
        dialog(v){
            if (v){
                if (this.estaHora){
                    this.datosSigno.hora = this.estaHora
                }
            }
        }
    },
}
</script>

<style>

</style>