var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"350","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"signoForm",attrs:{"onSubmit":"return false;"},on:{"submit":_vm.agrega},model:{value:(_vm.validaSigno),callback:function ($$v) {_vm.validaSigno=$$v},expression:"validaSigno"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" Agregar "+_vm._s(_vm.paramSign.nombreLargo)+" ")]),_c('v-card-text',[_c('div',{staticClass:"my-2"},[_c('v-expand-transition',[(_vm.alertHoraExistente)?_c('v-alert',{attrs:{"type":"warning","text":"","border":"left","dense":""}},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Ya existe un registro a esa hora")])]):_vm._e()],1),_c('span',[_vm._v(_vm._s(_vm.moment(_vm.moment(_vm.paramSign.estaFecha, "DD-MM-YYYY")).format("dddd MMMM DD, YYYY")))])],1),_c('v-text-field',{ref:"signoHora",attrs:{"rules":[
                            value => !!value || 'Requerido.',
                            value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                        ],"filled":"","rounded":"","clearable":"","dense":"","label":"Hora (HH:MM formato 24 hrs)"},on:{"blur":_vm.buscaSigno},model:{value:(_vm.datosSigno.hora),callback:function ($$v) {_vm.$set(_vm.datosSigno, "hora", $$v)},expression:"datosSigno.hora"}}),(!_vm.camposTa(_vm.paramSign.signo))?_c('v-text-field',{attrs:{"rules":[
                            value => !!value || 'Requerido.',
                            value => value >= _vm.paramSign.min || 'Valor MENOR al mínimo ('+_vm.paramSign.min+')',
                            value => value <= _vm.paramSign.max || 'Valor MAYOR al máximo ('+_vm.paramSign.max+')'
                        ],"type":"number","filled":"","rounded":"","clearable":"","dense":"","label":_vm.paramSign.nombreLargo},model:{value:(_vm.datosSigno.valor),callback:function ($$v) {_vm.$set(_vm.datosSigno, "valor", $$v)},expression:"datosSigno.valor"}}):_vm._e(),(_vm.camposTa(_vm.paramSign.signo))?_c('v-text-field',{attrs:{"rules":[
                            value => !!value || 'Requerido.',
                        ],"filled":"","rounded":"","clearable":"","dense":"","label":_vm.paramSign.nombreLargo},model:{value:(_vm.datosSigno.valor),callback:function ($$v) {_vm.$set(_vm.datosSigno, "valor", $$v)},expression:"datosSigno.valor"}}):_vm._e(),_c('v-text-field',{attrs:{"filled":"","rounded":"","clearable":"","dense":"","label":"Observaciones"},model:{value:(_vm.datosSigno.observaciones),callback:function ($$v) {_vm.$set(_vm.datosSigno, "observaciones", $$v)},expression:"datosSigno.observaciones"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","type":"submit","disabled":!_vm.validaSigno,"loading":_vm.loadingSigno}},[_vm._v(" Agregar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelaAgrega}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }